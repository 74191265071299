import {
  CreateButton,
  Datagrid,
  DateInput,
  FunctionField,
  ExportButton,
  List,
  NumberField,
  SelectInput,
  ShowButton,
  TextField,
  TopToolbar,
  FilterLiveSearch,
  RaRecord,
    FilterList, FilterListItem,
  Button as AdminButton,  
  useListContext,
  AutocompleteInput
} from "react-admin";
// import CustomReferenceField from "../Common/CustomReferenceField";
import { TimeEnum } from "../../enums/TimeEnum";
import { PaymentStatusEnum } from "../../enums/PaymentStatusEnum";
import { OneMealServiceStatus } from "../../enums/OneMealServiceStatus";
import MetabaseCustomerDashboard from "../Common/MetabaseCustomerDashboard";
import { UserType } from "../../enums/common";
import CallButton from "../Common/CallButton/CallButton";
import { Box, Button, Card, CardContent, InputLabel, Link, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { FilterAlt, FilterAltOff } from "@mui/icons-material";
import { setOmsSideFilter } from "../../store/reducers/customSelect";
import MetabaseTransactionButton from "../Common/MetabaseDashboardButton";
import { MetabaseDasboardId } from "../../enums/MetabsaeDashboardId";
import EventNoteIcon from '@mui/icons-material/EventNote';
import moment from "moment";
import CityService from "../../services/CityService";
import {useEffect, useState} from "react";
import CityResponse from "../../interfaces/City/CityResponse";
import TrackerBtn from "../Common/TrackerBtn";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

const FilterSidebar = () => {
  return (
      <Box
          sx={{
              marginTop: "15px",
              display: {
                  xs: 'none',
                  sm: 'block'
              },
              order: -1,
              width: '17em',
              marginRight: '1em',
          }}
      >
          <Card
          style={{
          maxHeight: '60%',
          overflow: 'auto'
          }}>
            <CardContent>
              <InputLabel >Cutomer Name</InputLabel>
              <FilterLiveSearch source="customerName" />
              <InputLabel >Cutomer Phone</InputLabel>
              <FilterLiveSearch source="customerPhoneNumber" />
              <InputLabel >Cook Name</InputLabel>
              <FilterLiveSearch source="cookName" />
              <InputLabel >Cook Phone</InputLabel>
              <FilterLiveSearch source="cookPhoneNumber" />
              <InputLabel >Trial Owner Name</InputLabel>
              <FilterLiveSearch source="trialOwnerName" />
              <InputLabel >Sector</InputLabel>
              <FilterLiveSearch source="sector" />
              <InputLabel >Cancelled By(Customer/admin)</InputLabel>
              <FilterLiveSearch source="cancelledBy"/>
              <FilterList
                label="Cook Acknowledge"
                icon={''}
                sx={{mb: 2}}
              >
                <FilterListItem
                    label="Yes"
                    value={{ cookAcknowledged: true }}
                />
                <FilterListItem
                    label="No"
                    value={{ cookAcknowledged: false }}
                />
            </FilterList>
              <FilterList
                label="Customer Acknowledge"
                icon={''}
                sx={{mb: 2}}
              >
                <FilterListItem
                    label="Yes"
                    value={{ isCheckinValid: true }}
                />
                <FilterListItem
                    label="No"
                    value={{ isCheckinValid: false }}
                />
            </FilterList>
              <InputLabel >Cook Accepted</InputLabel>
              <FilterLiveSearch source="numberOfCookAccepted" />
              <InputLabel >Shortlisted Cook</InputLabel>
              <FilterLiveSearch source="numberOfCookAllocated" />
              <InputLabel >Non-Allocated Trial(true/false)</InputLabel>
              <FilterLiveSearch source="localityId" />
            </CardContent>
          </Card>
      </Box>
  )
};
const ListActions = (props: any) => {
  const dispatch = useAppDispatch();
  const { setFilters } = useListContext();
    const resetFilter = () => {
      setFilters({}, [], false);
    };
  return(
  <TopToolbar>
      <AdminButton
        variant="text"
        size={"small"}
        onClick={resetFilter}
        startIcon={<FilterListOffIcon />}
        label="Clear filters"
      />
      <Link href={"#/CooksServeCalendar"} target={'_self'} underline="none">
          <Button size="small" variant={"text"} startIcon={<EventNoteIcon/>}>
              Open TimeTable
          </Button>
      </Link>
      <MetabaseTransactionButton dashboardId={MetabaseDasboardId.OMS} />
      <Button variant="text"
        size={"small"}
        onClick={() => {
          dispatch(setOmsSideFilter({
            omsSideFilter: !props.openSideFilter
          }))
        }}
        startIcon={props.openSideFilter ? <FilterAlt /> : <FilterAltOff />} color="primary"
      >
        Filter
      </Button>
      <CreateButton />
      <ExportButton />
  </TopToolbar>
)};

const cityService = new CityService();

const OneMealServiceList = (props: any) => {
  const showSideFilter = useAppSelector((s) => s.customSelect.value.omsSideFilter)
    const [cities, setCities] = useState<CityResponse[]>([]);
    const filters = [
        <SelectInput
            label="Filter by status"
            source="status"
            alwaysOn
            emptyText="Clear Filter"
            choices={Object.values(OneMealServiceStatus).map((key) => {
                return {
                    id: key,
                    name: key,
                };
            })}
        />,
        <SelectInput
            label="Payment Status"
            source="paymentStatus"
            alwaysOn
            emptyText="Clear Filter"
            choices={Object.values(PaymentStatusEnum).map((key) => {
                return {
                    id: key,
                    name: key,
                };
            })}
        />,
        <SelectInput
            label="Pre Checked In"
            source="preCheckedInAt"
            alwaysOn
            emptyText="Clear Filter"
            choices={[
                {
                    id: "YES",
                    name: 'Yes'
                },
                {
                    id: "NO",
                    name: 'No'
                }
            ]}
        />,
        <DateInput label="Date" source="date" alwaysOn />,
        <SelectInput
            label="Time"
            source="time"
            alwaysOn
            emptyText="Clear Filter"
            choices={Object.values(TimeEnum).map((value) => {
                return {
                    id: value,
                    name: value,
                };
            })}
        />,
        <AutocompleteInput
          label="City"
          source="cityId"
          choices={cities.map((eachCity: any) => ({
            id: eachCity?.id,
            name: eachCity?.name,
          }))}
          alwaysOn
        />,
        <SelectInput
            label="OMS Type"
            source="omsType"
            alwaysOn
            emptyText="Clear Filter"
            choices={[
                {
                    id: 'Normal OMS',
                    name: 'Normal OMS'
                },
                {
                    id: 'TR OMS',
                    name: 'TR OMS'
                }
            ]}
        />,
        <SelectInput
            label="Customer Acknowledge"
            source="isCheckinValid"
            alwaysOn
            emptyText="Clear Filter"
            choices={[
                {
                    id: true,
                    name: 'Yes'
                },
                {
                    id: false,
                    name: 'No'
                }
            ]}
        />,
    ];
    useEffect(() => {
        cityService.fetchAllCities(100, 0, {}, {}).then((res) => {
            setCities(res?.data);
        })
    }, []);
  return (
    <List {...props} className="toolbar-custom-css" filters={filters} actions={<ListActions openSideFilter={showSideFilter} />} aside={showSideFilter ? <FilterSidebar /> : null} sort={{ field: 'date', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false}>
        <ShowButton />
        <NumberField source="id" />
        <TextField source="customerName" sortable={false} />
        <MetabaseCustomerDashboard label="Customer Dashboard" />
        <FunctionField
          label="Customer Phone Number"
          render={(record: any) => (
            <CallButton
              toPhoneNumber={record?.customerPhoneNumber}
              userType={UserType.CUSTOMER}
            />
          )}
        />
        {/*<CustomReferenceField*/}
        {/*  label="House"*/}
        {/*  listName={"Houses"}*/}
        {/*  source={"houseId"}*/}
        {/*/>*/}
        {/* <WrapperField label="Address">
          <TextField width="30%" source="house.address" />
          <TextField width="30%" source="house.locality" />
          <TextField width="30%" source="house.city" />
        </WrapperField> */}
        <TextField label={'Sector'} source="areaName" sortable={true} />
        <TextField label={'Locality'} source="localityName" sortable={true} />
        <TextField source="date" sortable={true} />
        <TextField source="time" sortable={true} />
        <FunctionField
          emptyText="Not Available"
          label="Status"
          render={(record:any) =>
              <>
              <div>{record?.status}</div>
              {record?.preCheckedInAt&&record?.status===OneMealServiceStatus.COOK_CONFIRMED?<div>PRE CHECKED IN</div>:<></>}
              </>
          }
        /> 
        <FunctionField sx={{
          display: 'inline-block',
          width: '65px',
        }} label="Pay After Service" render={(record: RaRecord) => {
          if (record?.hasOptedPayAfterService) {
            return (
              <Typography fontSize={'0.875rem'} fontWeight={600} color={'#047a04'}>Opted</Typography>
            )
          }
          else {
            return (
              <Typography fontSize={'0.875rem'} fontWeight={600} color={'#c80101'}>Not Opted</Typography>
            )
          }
        }} />
        <TextField source="adminNotes" sortable={false} />
        <FunctionField
          label="Cook Name"
          render={(record: any) => {
            if (!record?.cook?.cookFullProfile?.firstName) {
              return `Not Available`
            }
            return `${record?.cook?.cookFullProfile?.firstName} ${record?.cook?.cookFullProfile?.lastName}`
          }
          }
        />
        <TrackerBtn label={'View Tracker'} />
        <FunctionField
          label="Cook Phone Number"
          render={(record: any) => (
              <CallButton
                  toPhoneNumber={record?.cook?.mobile}
                  userType={UserType.COOK}
              />
          )}
        />
        <TextField source="cancellationReason" sortable={false} />
        <TextField source="paymentStatus" sortable={false} />
        <FunctionField label="Created At" render={(record: RaRecord) => `${record?.createdAt ? moment(record?.createdAt).format('LLLL') : "Not Available"}`}/>
      </Datagrid>
    </List>
  );
};

export default OneMealServiceList;
