import { AutocompleteInput, BooleanField, Button, Datagrid, DateField, ExportButton, List, ReferenceField, ShowButton, TextField, TextInput, TopToolbar, useListContext } from "react-admin";
import { useAppSelector } from "../../store/hooks";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";
import { useEffect, useState } from "react";
import CityService from "../../services/CityService";
import CityResponse from "../../interfaces/City/CityResponse";
import {useNavigate} from "react-router-dom";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import FilterListOffIcon from "@mui/icons-material/FilterListOff";


interface AreaListProps{
  isMultiSelect?: boolean;
}
const cityService =new CityService();
const ListActions = (props: any) => {
    const { setFilters } = useListContext();
    const resetFilter = () => {
      setFilters({}, [], false);
    };
    const navigate = useNavigate();
  
    return (
      <TopToolbar>
        <Button
          variant="text"
          size={"small"}
          onClick={resetFilter}
          startIcon={<FilterListOffIcon />}
          label="Clear filters"
        />
        <Button
          variant="text"
          size={"small"}
          onClick={()=>{
            navigate("/Areas/create")
          }}
          startIcon={<ControlPointIcon />}
          label="Create"
        />
        <ExportButton/>
      </TopToolbar>
    );
};
const AreaList = (props:AreaListProps) => {
    const [cities, setCities] = useState<CityResponse[]>([]);
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);
    const checkMultiSelect = () => {
      return props.isMultiSelect?<></>:false
    }
    const filters =  [
        <TextInput label="Search by area Name" source="name" alwaysOn />,
        <TextInput label="Search by city id" source="cityId" alwaysOn />,
        <AutocompleteInput
        label="City"
        source="cityId"
        choices={cities.map((eachCity: any) => ({
          id: eachCity?.id,
          name: eachCity?.name,
        }))}
        alwaysOn
      />
    ]

    useEffect(() => {
        cityService.fetchAllCities(100, 0, {}, {}).then((res) => {
          setCities(res?.data || []);
        });
      }, []);
    
    return (
        <List filters={filters} actions={<ListActions/>}>
            <Datagrid bulkActionButtons={checkMultiSelect()}>
                {
                    isCustomSelectOpen &&
                    <ResourceSelectDialogButton />
                }
                <TextField source="id" />
                <TextField source="name" />
                <ReferenceField source="cityId" link="show" reference="Cities">
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField source="isServiceable" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default AreaList;